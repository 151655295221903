<template lang="pug">
.claims-info
  .claim(v-for="claim in claims")
    p.claim__line.claim__line_title {{ claimStatusInOneLine(claim, { noLastSentenceDot: true }) }}:
    p.claim__line {{ claim.claim }}
    p.claim__line <b>Статус:</b> {{ claimStatus(claim) }}
    p.claim__line <b>Сумма:</b> {{ claimAmount(claim) }}
    p.claim__line <b>Время создания:</b> {{ formatDate(claim.created_at) }}
</template>

<script>
import claims from '@/components/mixins/claims';
import utils from '@/components/mixins/utils';

export default {
  name: 'ClaimHistoryList',
  mixins: [
    claims,
    utils,
  ],
  props: {
    claims: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatDate(dateAsString) {
      const { date, time } = this.divideDate(dateAsString);
      return date + ' ' + time;
    },
  },
};
</script>

<style lang="scss" scoped>
.claim {
  margin-top: 12px;
  padding-bottom: 12px;
  border-bottom: 0.5px solid $gray200;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }

  &__line {
    margin-bottom: 0.5em;

    &_title {
      font-weight: bold;
    }
  }
}
</style>
