<template lang="pug">
  .main-content.layout-main__centering.container.pb-5(
  v-loading='isLoading',
  element-loading-text='Загрузка данных...'
  )
    DealInfo(v-if="deal" :deal="deal")
    DealStatus(v-if="deal" :deal="deal")
</template>
<script>
import {mapGetters} from 'vuex';
import DealInfo from '@/components/Deal/DealInfo.vue';
import DealStatus from '@/components/Deal/DealStatus.vue';
import { DEAL_LISTEN, DEAL_STOP_LISTEN, DEAL_CHANGES_RESET } from "@/store/actions/deal";

export default {
  components: {
    DealInfo,
    DealStatus,
  },
  data() {
    return {
      deal_id: 0
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'dealInfo',
    ]),
    isLoading() {
      return !this.deal;
    },
    deal () {
      return this.dealInfo(this.deal_id).info
    },
  },
  mounted() {
    this.deal_id = parseInt(this.$route.params.id) || 0;
    this.$store.dispatch(DEAL_LISTEN, { dealId: this.deal_id });
  },
  beforeDestroy() {
    this.$store.dispatch(DEAL_STOP_LISTEN, { dealId: this.deal_id });
    this.$store.commit(DEAL_CHANGES_RESET, { dealId: this.deal_id });
  },
}

</script>
<style>

</style>
