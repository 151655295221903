<template lang="pug">
  .change-main-block.p-0
    .steps-wrapper
      .steps-main-block(v-if="currentStep === 0")
        .deal-system-status__container
          i.el-icon-loading
          .deal-system-status__title {{ systemStatus }}
      DealStatusSeller(
        v-else-if="isSale",
        :currentStep="currentStep",
        :deal="deal",
        :isHaveRequisites="isHaveRequisites()",
        :isFinished="isFinished",
        :isCanceled="isCanceled",
        @acceptDialog="acceptDialog"
      )
      DealStatusBuyer(
        v-else,
        :currentStep="currentStep",
        :deal="deal",
        :isHaveRequisites="isHaveRequisites()",
        :isFinished="isFinished",
        :isCanceled="isCanceled",
        @acceptDialog="acceptDialog"
      )
      DealChat(:deal="deal", :isHistory="isHistory")
    DealButtons(
      :currentStep="currentStep",
      :deal="deal",
      :isHaveRequisites="isHaveRequisites()",
      :isFinished="isFinished",
      :isSale="isSale",
      @acceptDialog="acceptDialog"
    )
    safety(
      :show-popup="showSafetyPopup"
      @close="closeSafety"
      @code="getCode"
      :error="safety_error"
      :type="safety_type"
      @resend="resendSafety"
    )
</template>
<script>
import { mapGetters } from 'vuex';
import DealChat from '@/components/Chat/DealChat';
import DealStatusBuyer from '@/components/Deal/DealStatusBuyer';
import DealStatusSeller from '@/components/Deal/DealStatusSeller';
import DealButtons from '@/components/Deal/DealButtons';
import { DEAL_ACTION } from '@/store/actions/deal';
import analytics from '@/components/mixins/analytics';
import errors from '@/components/mixins/errors';
import security from '@/components/mixins/security';
import Safety from "@/components/Common/Safety";

export default {
  components: {
    DealChat,
    DealStatusBuyer,
    DealStatusSeller,
    DealButtons,
    Safety,
  },
  mixins: [
    analytics,
    security,
    errors,
  ],
  props: {
    deal: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      google2fa_secret: '',
      showSafetyPopup: false,
      otp_secret: '',
      safety_error: '',
      safety_type: 'twoFa',
      payload: {},
    }
  },
  computed: {
    ...mapGetters([
      'get_status',
    ]),
    currentStep() {
      const steps = {
        [this.get_status('Verification', 'id')]: 0,
        [this.get_status('Verified', 'id')]: 1,
        [this.get_status('Seller requisite', 'id')]: 1,
        [this.get_status('Paid', 'id')]: 2,
        [this.get_status('Finishing', 'id')]: 0,
        [this.get_status('Finished', 'id')]: 3,
        [this.get_status('Cancellation', 'id')]: 0,
        [this.get_status('Autocancellation', 'id')]: 0,
        [this.get_status('Canceled', 'id')]: 6,
        [this.get_status('In dispute', 'id')]: 5,
        [this.get_status('On time check', 'id')]: 4,
      };
      return steps[this.deal.status.id];
    },
    systemStatus() {
      const titles = {
        [this.get_status('Verification', 'id')]: 'Сделка подтверждается системой',
        [this.get_status('Finishing', 'id')]: 'Сделка завершается системой',
        [this.get_status('Cancellation', 'id')]: 'Сделка отменяется системой',
        [this.get_status('Autocancellation', 'id')]: 'Сделка отменяется автоматически',
      };
      return titles[this.deal.status.id];
    },
    isSale() {
      return !!this.deal.is_sale;
    },
    isFinished() {
      return this.get_status('Finished', 'id') === this.deal.status.id || this.get_status('On time check', 'id') === this.deal.status.id;
    },
    isHistory() {
      return this.isFinished || this.isCanceled;
    },
    isCanceled() {
      return this.get_status('Canceled', 'id') === this.deal.status.id;
    },
  },
  methods: {
    isHaveRequisites() {
      return !!this.deal.requisites;
    },
    dealAction(action, info) {
      this.sendAnalytics('Deal', `deal_${action}`, false);
      if (action === 'paid') {
        this.sendAnalytics('Deal', `send_submit`, false, ['google', 'yandex']);
      } else if (action === 'finish') {
        this.sendAnalytics('Deal', 'create_submit', false, ['google', 'yandex']);
        this.sendAnalytics('Deal', 'Purchase', false, ['facebook']);
      }
      let payload = { dealId: this.deal.id, action }
      if (info !== '') {
        payload.info = info;
      }
      this.safety_error = ''
      payload.google2fa_secret = this.google2fa_secret;
      payload.otp = this.otp_secret;
      this.$store.dispatch(DEAL_ACTION, payload)
        .then(() => {
          this.closeSafety();
        })
        .catch((error) => {
          this.payload = payload;
          const safety_errors = this.haveSecurityError(error);
          if (safety_errors.have) {
            if (safety_errors.need_open && this.showSafetyPopup === false) {
              this.safety_type = safety_errors.type;
              this.showSafetyPopup = true;
            } else {
              this.safety_error = safety_errors.message;
            }
          } else if (error && error.errors) {
            this.$message.error({
              dangerouslyUseHTMLString: true,
              message: this.formatErrors(error.errors),
            });
          } else {
            this.$message.error(error.message);
          }
        });
    },
    acceptDialog({ action, typeText, info = '' }) {
      this.$confirm('Помните, это действие необратимо!', `${typeText}?`, {
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        type: 'warning'
      }).then(() => {
        this.dealAction(action, info);
      })
    },
    closeSafety() {
      this.showSafetyPopup = false;
      this.google2fa_secret = '';
      this.otp_secret = '';
    },
    getCode({ code, type }) {
      if (type === 'otp') {
        this.otp_secret = code;
        this.payload.otp = code;
      } else {
        this.google2fa_secret = code;
        this.payload.google2fa_secret = code;
      }
      this.dealAction(this.payload.action, this.payload.info ? this.payload.info : '')
    },
    resendSafety() {
      this.safety_error = '';
      let payload = this.payload;
      delete payload.otp
      this.dealAction(this.payload.action, this.payload.info ? this.payload.info : '')
    },
  }
}
</script>
<style>
.deal-system-status__container {
  text-align: center;
  margin-top: calc(50% - 90px);
  font-size: 40px;
}

.deal-system-status__container .deal-system-status__title {
  font-size: 20px
}
</style>
