<template lang="pug">
  .steps-main-block
    .step-main-block(:class="{active: currentStep === 1}")
      .step-number
        | 1
      .step-title
        | Отправьте деньги продавцу
      .step-description
        | {{ `Отправьте ${deal.fiat_amount} ${deal.ad.currency.code.toUpperCase()} ${this.deal.bank ? `в банк «${this.deal.bank.label}»` : ''} по реквизитам продавца и нажмите кнопку «Подтвердить»` }}
      .place-for-number(v-if="isHaveRequisites")
        .place-for-scroll(ref="vs_req")
          .payment-number-s(@click="copyToClipboard(deal.requisites.seller_info)") {{ deal.requisites.seller_info }}
      .place-for-number(v-else)
        | Ожидайте отправки платежных реквизитов от продавца, обычно это занимает меньше минуты.
      .step-description
        | * Нажмите на реквизит, чтобы скопировать
      .pb-4.pt-2(v-if="currentStep === 1 || currentStep === 2")
        button.btn.btn__main.w-100(
          @click="acceptDialog('paid', 'Подтвердить оплату')",
          type='button',
          :disabled="!isHaveRequisites || currentStep !== 1"
        ) {{ currentStep === 1 ? 'Подтвердить оплату' : 'Оплата подтверждена'}}
    .step-main-block(:class="{active: currentStep !== 1}")
      .step-number
        | 2
      .step-title
        | Поступление криптовалюты на счет
      .step-description
        | Ожидайте поступления криптовалюты на ваш счет в Finex. Обычно это занимает не более 5 минут.
      .payment-status
        .name-payment-status
          | поступление
        .icon-payment-status(v-if="isFinished")
          i.checked
          span готово
        .icon-payment-status.icon-payment-status-canceled(v-else-if="isCanceled")
          i.canceled
          span отменено
        .icon-payment-status(v-else)
          i.waiting
          span в ожидании
</template>
<script>
export default {
  props: {
    currentStep: {
      type: Number,
      default: 0,
    },
    deal: {
      type: Object,
      default() {
        return {};
      },
    },
    isHaveRequisites: {
      type: Boolean,
      default: false,
    },
    isFinished: {
      type: Boolean,
      default: false,
    },
    isCanceled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {
    acceptDialog(action, typeText, info = '') {
      this.$emit('acceptDialog', {action, typeText, info})
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value)
        .then(() => {
          this.$message({
            message: 'Адрес кошелька успешно скопирован',
            type: 'success',
          });
        })
        .catch(err => {
          console.log('Ошибка при копировании адреса', err);
        })
    },
  },
}
</script>
<style lang="scss" scoped>

  .place-for-number {
    &::-webkit-scrollbar {
      cursor: pointer;
      position: absolute;
      margin: auto;
      transition: opacity 0.5s ease 0s;
      user-select: none;
      border-radius: inherit;
      height: 61.3452%;
      width: 6px;
      transform: translateY(0%);
      left: 0px;
      right: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(188, 201, 239);
      cursor: pointer;
      position: absolute;
      margin: auto;
      transition: opacity 0.5s ease 0s;
      user-select: none;
      border-radius: inherit;
      height: 61.3452%;
      opacity: 1;
      width: 6px;
      transform: translateY(0%);
      left: 0px;
      right: 0px;
    }
    .place-for-scroll {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .payment-number-s {
        width: 100%;
        word-wrap: break-word;
      }
    }
  }

  .name-payment-status {
    min-width: 100px;
  }
</style>
