<template lang="pug">
  .transaction-score(
  v-loading="isLoading"
  element-loading-text="Загрузка..."
  )
    .t-s-content
      h2 {{`Как вы оцените ${deal.is_sale ? 'Покупателя' : 'Продавца'}?`}}
      p Отрицательный отзыв автоматически заблокирует этого пользователя для вас.
      .smiles-block(v-if="!showCommentForm")
        span.cursor-link.deal-rating-image__container(
        :class="{'deal-rating-active': feedbackType === 'positive'}",
        @click="setActiveCommentForm('positive')"
        )
          figure
            .deal-rating-image.deal-rating-positive
            figcaption Хорошо
        span.cursor-link.deal-rating-image__container(
        :class="{'deal-rating-active': feedbackType === 'neutral'}",
        @click="setActiveCommentForm('neutral')"
        )
          figure
            .deal-rating-image.deal-rating-neutral
            figcaption Нейтрально
        span.cursor-link.deal-rating-image__container(
        :class="{'deal-rating-active': feedbackType === 'negative'}",
        @click="setActiveCommentForm('negative')"
        )
          figure
            .deal-rating-image.deal-rating-negative
            figcaption Плохо
      Feedback(v-else :feedbackType="feedbackType" @sendComment="sendComment")
</template>
<script>
  import {mapGetters} from 'vuex';
  import {
    DEAL_GET_REVIEWS,
    DEAL_SEND_REVIEW,
  } from '@/store/actions/deal';
  // import AddNote from '@/components/Dashboard/Deals/AddNote.vue';
  import Feedback from '@/components/Deal/Feedback.vue';


  export default {
    components: {
      // AddNote,
      Feedback
    },
    props: {
      deal: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    data() {
      return {
        feedbackType: '',
        time: false,
        rating: true,
        showCommentForm: false,
        negativeFeedbackWithMessage: false,
        feedbackMessage: '',
      }
    },
    computed: {
      ...mapGetters([
        'loading',
        'dealReview',
        'getProfile',
      ]),
      isLoading(){
        return this.loading(DEAL_GET_REVIEWS) === 'loading' || this.loading(DEAL_SEND_REVIEW) === 'loading';
      },
      haveReview() {
        return this.dealReview(this.deal.author.id === this.getProfile.id ? this.deal.offer.id : this.deal.author.id);
      },
      setReview(){
        return this.haveReview.trust || this.feedbackType;
      },
    },
    watch: {
      haveReview(val) {
        if(val){
          this.feedbackType = this.setReview;
        }
      },
    },
    mounted() {
      this.$store.dispatch(DEAL_GET_REVIEWS);
      this.feedbackType = this.setReview;
      this.feedbackMessage = this.haveReview ? this.haveReview.message : ' ';
    },
    methods: {
      changeContentType(type) {
        this.$emit('changeContentType', type);
      },
      sendReview(comment = '') {
        let rates = {
          'positive': 3,
          'neutral': 2,
          'negative': 0,
        };
        let data = {
          rate: rates[this.feedbackType],
          trust: this.feedbackType,
          text: comment,
        };
        this.$store.dispatch(DEAL_SEND_REVIEW, { dealId: this.deal.id, userId: this.deal.offer.id, data })
          .then(() => {
            this.$message({
              message: 'Оценка сохранена!',
              type: 'success',
            });
          }).catch(() => {
          this.$message.error('Произошла ошибка при сохранении оценки, попробуйте позже');
        });
      },
      sendComment(comment) {
        this.sendReview(comment ? comment : ' ');
        this.showCommentForm = false;
      },
      setActiveCommentForm(type) {
        this.feedbackType = type;
        this.showCommentForm = true;
      },
    }
  };
</script>
<style>
  .t-s-content {
    max-width: 300px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .smiles-block {
    margin-bottom: 10px;
  }

  .note-about {
    margin-top: 10px;
  }
</style>
