<template lang="pug">
  .requsites-adder
    .requsites-create
      .requsites-create-main
        .create-types
          .create-types__item(
            v-for="type in getRequisiteTypes",
            :class="{'create-types__item--active': selectedType.id === type.id}"
            @click="selectedType = type"
          )
            span {{ type.title }}
        RequisiteManualInput(
          :country_id="getProfile.country.id",
          :selectedType="selectedType",
          @addRequisite="addRequisite",
        )

</template>

<script>
  import { mapGetters } from 'vuex';
  import { REQUISITE_TYPES } from '../../../store/actions/ad';
  import RequisiteManualInput from "./RequisiteManualInput";

  export default {
    components: {RequisiteManualInput},
    data() {
      return {
        selectedType: {
          id: 2,
          alias: 'card',
          title: 'Карта',
        },
      }
    },
    computed: {
      ...mapGetters([
        'getRequisiteTypes',
        'getProfile',
      ]),
    },
    created() {
      this.$store.dispatch(REQUISITE_TYPES);
    },
    methods: {
      addRequisite(data) {
        this.$emit('addRequisite', data);
      },
    },
  }
</script>

<style lang="scss" scoped>

  .requsites-adder {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100px;
  }

  .requsites-create {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    padding-bottom: 0;

    &-main {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .create-types {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 20px;

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $blue100;
      border: 1px solid $blue300;
      border-radius: 6px;
      padding: 0 15px;
      font-size: 14px;
      margin-right: 15px;

      &--active {
        background: $blue300;
      }

      &:hover {
        cursor: pointer;
        transition:  0.3s;
        background: $blue300;
      }
    }
  }
</style>
