<template lang="pug">
  div(:class="messageContainerClass")
    div(:class="messageContentClass")
      div(:class="messageTitleClass")
        div(v-if="!isAdminMessage" :class="messageDateClass")
          | {{ messageDate }}
        div(:class="messageNameClass")
          | {{author}}
      div(:class="messageInsetClass")
        h4.message-system__title(v-if='isSystem')
        | {{ message.title }}
        p.mb-0(v-if="message.message !== '[Attachment]'" v-html="message.message")
        p.mb-0.message-image(v-if="message.image", :class="messageInsetClass")
          img.chat-image(:src="message.image", @click="openImage(message.image)")
    .image-full-popup__container(v-if="dialogVisible")
      .image-full-popup__field
        .image-close__field(@click="closeFullImage()")
        .image-full-popup__close-btn(@click="closeFullImage()")
          i(class="el-icon-close")
        .image-full-popup__block
          img(:src="currentImage")

</template>

<script>
  import utilsMixin from '../mixins/utils';

  export default {
    mixins: [utilsMixin],
    props: {
      message: {
        type: Object,
        default() {
          return {};
        },
      },
      partner: {
        type: Object,
        default() {
          return {};
        }
      },
      user: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data(){
      return {
        dialogVisible: false,
        currentImage: null,
      }
    },
    computed: {
      isSystem() {
        return this.type === 'system'
      },
      author() {
        if(this.isAdminMessage) {
          return 'Администрация Finex';
        } else if(this.message.author_id === this.partner.id){
          return this.partner.login;
        } else {
          return this.user.login;
        }
      },
      isYouMessageAuthor() {
        return this.message.author_id === this.user.id;
      },
      isPartnerMessageAuthor() {
        return this.message.author_id === this.partner.id;
      },
      isAdminMessage() {
        return this.message.is_admin;
      },
      messageContainerClass() {
        return {
          'sent': this.isPartnerMessageAuthor || this.isAdminMessage,
          'replies': this.isYouMessageAuthor
        }
      },
      messageContentClass() {
        return {
          'content-sent': this.isPartnerMessageAuthor || this.isAdminMessage,
          'content-replies': this.isYouMessageAuthor
        }
      },
      messageTitleClass() {
        return {
          'title-sent': this.isPartnerMessageAuthor || this.isAdminMessage,
          'title-replies': this.isYouMessageAuthor
        }
      },
      messageDateClass() {
        return {
          'data-sent': this.isPartnerMessageAuthor || this.isAdminMessage,
          'data-replies': this.isYouMessageAuthor
        }
      },
      messageNameClass() {
        return {
          'name-sent': this.isPartnerMessageAuthor || this.isAdminMessage,
          'name-replies': this.isYouMessageAuthor
        }
      },
      messageInsetClass() {
        return {
          'content-inset': this.isPartnerMessageAuthor,
          'content-inset-replies': this.isYouMessageAuthor,
          'content-inset-admin': this.isAdminMessage
        }
      },
      messageDate() {
        const date = this.divideDate(this.message.created_at);
        return `${date.date} ${date.time}`;
      },
    },
    mounted() {
      this.message.message = this.message.message.replace(new RegExp('\n', 'g'), '<br/>');
    },
    methods: {
      openProfile(){
        this.$router.push({
          name: 'Profile',
          params: {
            id: this.message.author_id
          }
        })
      },
      openImage(image) {
        this.currentImage = image;
        this.dialogVisible = true;
      },
      closeFullImage() {
        this.dialogVisible = false;
        this.currentImage = '';
      }
    },
  }
</script>

<style lang="scss">
  .chat-image {
    width: 100%;
  }

  .message-image {
    cursor: pointer;
  }

  .image-full-popup__container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000000e0;
    z-index: 999;
  }

  .image-full-popup__field {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-full-popup__close-btn {
    position: absolute;
    right: 9px;
    top: 5px;
    cursor: pointer;
    z-index: 2;
  }

  .image-full-popup__close-btn i {
    font-size: 50px;
    color: white;
  }

  .image-close__field {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .image-full-popup__block {
    position: absolute;
    z-index: 555;
  }

  .image-full-popup__block img {
    max-height: 95vh;
  }



</style>
