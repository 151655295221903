<template lang="pug">
  .chat-main-block
    .title-chat(v-if="isShowDealRate")
      span.cursor-link(@click="changeRateStatus(false)") В чат
    .title-chat(v-else)
      h2 Чат с {{ deal.is_sale ? 'покупателем' : 'продавцом' }}
    .chat-content.scroll-vertical(
      v-loading='isLoading',
      element-loading-text='Загрузка данных...',
    )
      vue-scroll(v-if='messages.length > 0 && !isShowDealRate', ref='vs', @handle-scroll='handleScroll')
        .message-load
          button.btn.btn-success.w-50.mt-2(v-if='moreMessages', @click='loadChat') Загрузить еще...
        div(v-for='(message, index) in messages', :data-date='!message.is_admin ? divideDate(message.created_at).date : ""', :key='message.id')
          message(
            :message='message',
            :partner='partnerInfo',
            :user="getProfile",
          )
          .chat-deal-rate
            button.btn.btn__main.w-50.mt-2(
            v-if="isHistory && !isShowDealRate && messages.length === index + 1",
            @click="changeRateStatus(true)"
            ) Оценить {{deal.is_sale ? 'покупателя' : 'продавца'}}
      .chat-deal-rate
        button.btn.btn__main.w-50.mt-2(
          v-if="isHistory && !isShowDealRate && messages.length === 0",
          @click="changeRateStatus(true)"
        ) Оценить {{deal.is_sale ? 'покупателя' : 'продавца'}}
      DealRate(:deal="deal" v-if="isHistory && isShowDealRate")
    .message-input-main(v-if="!isShowDealRate")
      .message-icon.message-pin-file
        el-upload(
          action="",
          class='upload',
          ref="upload",
          :http-request="loadImage",
          :before-upload="beforeUpload",
          :before-remove="beforeRemove",
          :on-remove="clearChosenFiles",
          :limit=1,
          :auto-load="false",
        )
          img(src='/img/clip.svg')
      textarea(
        ref="chatInput",
        resize="none",
        v-model='message',
        rows="1",
        :minHeight="10",
        :maxHeight="100",
        placeholder='Напишите сообщение',
        maxlength='1000',
        :disabled="inProgress",
        @keyup="sendMessage"
      )
      .message-icon.message-send-message(@click='sendMessage')
    AddNote(v-else :deal="deal")

</template>
<script>
  import Message from '@/components/Chat/Message.vue';
  import {DEAL_CHAT_CLOSE, DEAL_CHAT_OPEN} from '@/store/actions/events';
  import {DEAL_CHAT_GET_MESSAGES, DEAL_CHAT_MESSAGE} from '@/store/actions/deal';
  import {mapGetters} from 'vuex';
  import utilsMixin from '../mixins/utils';
  import cfg from '../../../config';
  import DealRate from '@/components/Deal/DealRate';
  import AddNote from '@/components/Deal/AddNote';
  import analytics from '@/components/mixins/analytics';

  export default {
    components: {Message, DealRate, AddNote},
    mixins: [utilsMixin, analytics],
    props: {
      deal: {
        type: Object,
        default() {
          return {};
        },
      },
      isHistory: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        message: '',
        messagesLength: 0,
        currentPage: 1,
        currentDay: this.divideDate(new Date()).date,
        scrollToEnd: true,
        form: new FormData(),
        haveImage: false,
        inProgress: false,
        isShowDealRate: false,
        chatInput: null,
      }
    },
    computed: {
      ...mapGetters([
        'loading',
        'dealInfo',
        'getProfile',
        'chatPagination',
      ]),
      partnerInfo(){
        return this.deal.author.id === this.profile.id ? this.deal.offer : this.deal.author
      },
      dealId() {
        return this.deal.id;
      },
      messages() {
        return _.orderBy(this.dealInfo(this.dealId).messages, ['created_at', 'id'], ['asc']);
      },
      profile() {
        return this.getProfile;
      },
      moreMessages() {
        return this.currentPage < this.chatPagination.total_pages;
      },
      isLoading() {
        return this.loading(DEAL_CHAT_GET_MESSAGES) === 'loading';
      },
    },
    watch: {
      'deal': {
        handler(newVal, oldVal) {
          if (oldVal && oldVal.chat && oldVal.chat.id) {
            this.closeChat(oldVal.chat.id);
          }
          if (newVal && newVal.chat && newVal.chat.id) {
            this.startChat(newVal.chat.id);
          }
        },
        deep: true,
        immediate: true,
      },
      'messages'(val) {
        if (val.length !== this.messagesLength) {
          if (this.$refs.vs) {
            this.$nextTick(() => {
              if (this.scrollToEnd) {
                this.autoScroll();
              }
            });
          }
          this.messagesLength = val.length;
        }
      },
      'message'(val) {
        if(val.length === 0){
          this.setDefaultChatInputHeight();
        }
      },
      isHistory() {
        this.isShowDealRate = true;
      }
    },
    mounted() {
      this.chatInput = this.$refs.chatInput;
    },
    beforeDestroy() {
      this.closeChat();
    },
    methods: {
      startChat() {
        if (this.deal) {
          this.$store.dispatch(DEAL_CHAT_OPEN, {
            chatId: this.deal.chat ? this.deal.chat.id : 0,
            dealId: this.dealId,
            pagination: {page: this.currentPage},
          }).then(() => {
            if (this.deal.chat) {
              if (this.scrollToEnd) {
                this.autoScroll();
              } else {
                this.autoScrollEnable();
              }
            }
          });
        }
      },
      closeChat() {
        this.$store.dispatch(DEAL_CHAT_CLOSE, {
          chatId: this.deal.chat ? this.deal.chat.id : 0,
          dealId: this.dealId,
        });
      },
      sendMessage(event) {
        if (event.keyCode == 13 || event.type == 'click') {
          if(event.shiftKey){
            this.resizeInput();
            event.stopPropagation();
          } else {
            this.sendMessageRequest();
          }
        }
      },
      resizeInput(){
        let current_height = this.chatInput.clientHeight;
        let additional_height = 25;
        if(current_height >= 200){
          this.chatInput.style.overflow = 'auto';
        } else {
          this.chatInput.style.height = current_height + additional_height + 'px';
        }
      },
      setDefaultChatInputHeight(){
        this.chatInput.style.height = 38 + 'px';
      },
      sendMessageRequest(){
        if (this.inProgress) return;
        this.autoScrollEnable();
        let msg = _.trim(this.message);

        if (msg || this.haveImage) {
          if (this.haveImage && msg === '') {
            msg = '[Attachment]';
          }
          this.form.append('message', msg);
          this.inProgressEnable();
          this.$store.dispatch(DEAL_CHAT_MESSAGE, {
            dealId: this.dealId,
            form: this.form,
          }).then(() => {
            this.form = new FormData();
            this.sendAnalytics('Deal', 'chat_send_message', false);
            this.inProgressDisable()
              .clearChosenFiles()
              .autoScroll();
          }).catch(error => {
            let message = '';
            if (error.status === 403) {
              message = 'Не удалось отправить сообщение, возможно пользователь заблокирован вами';
            } else {
              message = 'Ошибка отправки сообщения';
            }
            this.$message({
              message,
              type: 'error',
            });
            this.inProgressDisable();
          });
        }
        this.message = '';
      },
      setFocusToInput() {
        this.$nextTick(() => {
          this.$refs.chatInput.focus();
        })
      },
      inProgressEnable() {
        this.inProgress = true;
        return this;
      },
      inProgressDisable() {
        this.inProgress = false;
        this.setFocusToInput();
        return this;
      },
      autoScroll() {
        if (this.$refs['vs']) {
          this.$refs['vs'].scrollTo({y: '100%'});
          return this;
        }
      },
      loadChat() {
        this.autoScrollDisable()
            .increaseCurrentPage()
            .startChat(this.dealId);
      },
      autoScrollEnable() {
        this.scrollToEnd = true;
        return this;
      },
      autoScrollDisable() {
        this.scrollToEnd = false;
        return this;
      },
      increaseCurrentPage() {
        this.currentPage = this.currentPage + 1;
        return this;
      },
      handleScroll(vertical, horizontal, nativeEvent) {
        const doms = this.$refs['vs'].getCurrentviewDom();
        let index = 0;
        if (!doms[0].dataset.date) {
          index = 1;
        }
        this.currentDay = doms[index].dataset.date;
      },
      loadImage(data) {
        this.form.append('image', data.file);
        this.haveImage = true;
      },
      beforeRemove() {
        this.haveImage = false;
      },
      beforeUpload(file) {
        let types = [
          'image/jpeg',
          'image/jpg',
          'image/png',
          'image/gif',
        ];
        if (types.indexOf(file.type) === -1 || file.size > parseInt(cfg.message_max_filesize)) {
          this.$message.error('Изображение может быть только jpg/png/gif и не больше 2 МБ');
          return false;
        }
        return true;
      },
      clearChosenFiles() {
        this.form = new FormData();
        this.haveImage = false;
        this.$refs['upload'].clearFiles();
        return this;
      },
      changeRateStatus(status) {
        this.isShowDealRate = status;
        if (!status) {
          this.$nextTick(() => {
            this.autoScroll();
          });
        }
      }
    },
  }
</script>
<style>
  .message-input-main .el-upload-list.el-upload-list--text {
    position: absolute;
    max-width: 300px;
    margin-top: 2px;
  }
</style>
<style lang="scss" scoped>
  .chat-deal-rate {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
  .replies {
    margin-right: 70px;
  }
  .upload_image {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    .upload {
      ul {
        position: absolute;
        top: -70px;
        left: -10px;
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .message-load {
    text-align: center;
    margin: 0 auto 20px;
  }

  .message-input-main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 10px 40px 10px;
    background: $white;
    box-shadow: 0px -17px 20px rgba(0, 0, 0, 0.2);


    //.chat-message__input {
    //  width: 100%;
    //  border: 1px solid rgb(221, 221, 221);
    //  border-radius: 4px;
    //  padding: 6px 8px;
    //  resize: none;
    //  overflow: hidden;
    //  transition: all 0.05s ease-out;
    //  height: 38px;
    //}

    .message-icon {
      width: 30px;
      height: 24px;
      margin: 0 10px;
      cursor: pointer;
    }

    .message-send-message {
      background: url(/img/send-icon.svg) no-repeat center;
    }
  }

</style>
