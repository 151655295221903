<template lang="pug">
  .buttons-bottom-block
    .buttons-bottom-block__inner(v-if="showCancelButton || currentStep === 2")
      .chat-main-block.text-center
        button.btn.challenge-purchase(
          v-if="currentStep === 2",
          @click="acceptDialog('dispute', 'Оспорить покупку')",
          :disabled="showTimer",
        )
          | Оспорить покупку&nbsp;
          span(v-if="showTimer")
            countdown(
            :transform="transformCountdown",
            :time="leftTime",
            :interval='1000',
            :key="`countdown-${deal.id}`",
            tag='span',
            @end="hideTimer"
            )
              template(slot-scope='props')
                | ({{ props.minutes }}:{{ props.seconds }})
      .chat-main-block.text-center
        button.btn.btn__ghost_light(
        v-if="showCancelButton",
        @click="cancelDeal()"
        ) Отменить покупку
</template>
<script>
  import config from '../../../config';
  export default {
    props: {
      currentStep: {
        type: Number,
        default: 0,
      },
      deal: {
        type: Object,
        default() {
          return {};
        },
      },
      isHaveRequisites: {
        type: Boolean,
        default: false,
      },
      isFinished: {
        type: Boolean,
        default: false,
      },
      isSale: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showTimer: false,
        leftTime: 0,
      }
    },
    computed: {
      showCancelButton() {
        return (!this.isSale && (this.currentStep === 1 || this.currentStep === 2)) || (!this.isSale && this.currentStep === 5);
      }
    },
    watch: {
      currentStep(step) {
        if (step === 2) {
          this.checkTimer();
        }
      },
    },
    mounted() {
      if (this.currentStep === 2) {
        this.checkTimer();
      }
    },
    methods: {
      checkTimer(){
        let diff = this.$moment(this.deal.status.created_at, 'YYYY-MM-DD HH:mm:ss ZZ').add(config.dealDisputeAllowTime, 'm').diff(this.$moment());
        if(diff > 0){
          this.showTimer = true;
          this.leftTime = diff;
        } else {
          this.showTimer = false;
          this.leftTime = 0;
        }
      },
      acceptDialog(action, typeText, info = '') {
        this.$emit('acceptDialog', {action, typeText, info})
      },
      cancelDeal() {
        const amountDeal = this.deal.fiat_amount;
        if (this.currentStep === 2) {
          this.$prompt(`Вы хотите отменить уже оплаченную сделку, если уверены, то введите сумму сделки в валюте - ${amountDeal} `, 'Подтверждение отмены', {
            confirmButtonText: 'Подтвердить',
            cancelButtonText: 'Отмена',
            inputPattern: /\d+(\.\d{2})?/,
            inputErrorMessage: 'Введите в поле верную сумму сделки в валюте',
            inputValidator: this.checkAmount,
          }).then(() => {
            this.acceptDialog('cancel', 'Отменить покупку');
          })
        } else {
          this.acceptDialog('cancel', 'Отменить покупку');
        }
      },
      checkAmount(amount) {
        const amountInput = amount;
        const amountDeal = this.deal.fiat_amount;
        if (amountInput === amountDeal) {
          return true;
        } else {
          return false;
        }
      },
      hideTimer() {
        this.showTimer = false;
        this.leftTime = 0;
      },
      transformCountdown(props) {
        Object.entries(props).forEach(([key, value]) => {
          const digits = value < 10 ? `0${value}` : value;
          props[key] = `${digits}`;
        });
        return props;
      },
    },
  }
</script>
<style scoped>
  .buttons-bottom-block{
    flex-direction: column;
    width: 50%;
  }
  .buttons-bottom-block__inner{
    width: 100%;
    padding: 20px;
  }
  .cancel-purchase, .challenge-purchase{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0;
  }
</style>
