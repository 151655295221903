export default {
  methods: {
    /**
     * Choose Russian word form for counted elements. 
     * @param {number} n Number of items
     * @param {{ one: string, two: string, five: string }} wordForms
     * 
     * @example ```js
     * const dealWordForms = {
     *   one: 'сделка',
     *   two: 'сделки',
     *   five: 'сделок',
     * };
     * 
     * countedElementsNumberWordFormRu(0, dealWordForms) === 'сделок'
     * countedElementsNumberWordFormRu(1, dealWordForms) === 'сделка'
     * countedElementsNumberWordFormRu(2, dealWordForms) === 'сделки'
     * countedElementsNumberWordFormRu(3, dealWordForms) === 'сделки'
     * countedElementsNumberWordFormRu(5, dealWordForms) === 'сделок'
     * countedElementsNumberWordFormRu(11, dealWordForms) === 'сделок'
     * ```
     */
    countedElementsNumberWordFormRu(number, wordForms) {
      const lastDigit = Math.abs(number) % 10;
      const lastTwoDigits = Math.abs(number) % 100;
  
      return (
        11 <= lastTwoDigits && lastTwoDigits <= 14 ? 
          wordForms.five :
        lastDigit === 1 ?
          wordForms.one :
        2 <= lastDigit && lastDigit <= 4 ?
          wordForms.two :
          wordForms.five
      );
    },
  },
};
