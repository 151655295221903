<template lang="pug">
  .deal-rate-note__container
    p.note-about
      | Можете оставить личную заметку #[nobr об этом] пользователе.
      | Это пригодится #[nobr в будущем]
    .massage-input-main
      textarea(
        cols='30',
        rows='10',
        :min-height='20',
        :max-height='125',
        v-model='noteText',
        :disabled="!isNoteEdit",
        ref="noteTextarea",
        placeholder='Ваша заметка',
        maxlength='1000',
        @keydown.13='editUserNote()'
      )
      i.send-message.el-icon-edit.cursor-link(v-if="!isNoteEdit" @click="changeEditStatus(true)")
      span.send-message(v-else @click='editUserNote()')
</template>
<script>
  import {mapGetters} from 'vuex';
  import { NOTE_PUT, NOTE_DELETE_USER, NOTE_LIST } from '@/store/actions/note';

  export default {
    props: {
      deal: {
        type: Object,
        default() {
          return {};
        },
      }
    },
    data() {
      return {
        noteText: '',
        isNoteEdit: false,
      }
    },
    computed:{
      ...mapGetters([
        'getNotes',
        'loading',
        'getProfile'
      ]),
      noteUserId() {
        return this.getProfile.id === this.deal.offer.id ? this.deal.author.id : this.deal.offer.id;
      },
      currentNote(){
        return _.find(this.getNotes, (note) => note.recipient.id === this.noteUserId );
      },
      isLoading(){
        return this.loading(NOTE_LIST) === 'loading';
      },
    },
    watch: {
      currentNote(val){
        if(val){
          this.noteText = val.note;
        }
      }
    },
    mounted() {
      this.$store.dispatch(NOTE_LIST);
      if(this.currentNote){
        this.noteText = this.currentNote.note;
      }
    },
    methods: {
      changeEditStatus(status) {
        this.isNoteEdit = status;
        this.setFocusOnTextarea();
      },
      setFocusOnTextarea() {
        this.$nextTick(() => {
          this.$refs.noteTextarea.focus();
        });
      },
      editUserNote() {
        if (this.isNoteEdit) {
          if (this.noteText === '') {
            if (this.currentNote) {
              this.$store.dispatch(NOTE_DELETE_USER, {
                userId: this.noteUserId,
                noteId: this.currentNote.id,
              })
                .then(() => {
                  this.$message({
                    message: 'Успешно сохранено!',
                    type: 'success',
                  });
                  this.changeEditStatus(false);
                }).catch(() => {
                this.$message.error('Произошла ошибка при сохранении заметки, попробуйте позже');
              });
            } else {
              this.$message.info('Заметка не может быть пустой!');
              this.setFocusOnTextarea();
            }
          } else {
            this.$store.dispatch(NOTE_PUT, {userId: this.noteUserId, data: this.noteText})
              .then(() => {
                this.$message({
                  message: 'Заметка успешна сохранена!',
                  type: 'success',
                });
                this.changeEditStatus(false);
              }).catch(() => {
              this.$message.error('Произошла ошибка при сохранении заметки, попробуйте позже');
            });
          }
        } else {
          this.changeEditStatus(true);
          this.setFocusOnTextarea();
        }
      },
    }
  }
</script>
<style lang="scss">
.massage-input-main .el-icon-edit {
  background: none;
  color: $blue600;
  padding-left: 0;
}

.note-about {
  padding: 0 15px;
  font-size: 14px;
  text-align: center;
}

</style>
