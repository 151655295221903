<template lang="pug">
  .steps-main-block
    RequisitePopup(
      v-if="selectWindow",
      :requisites="requisites",
      @sendRequisite="sendSavedRequisite",
      @closeWindow="selectWindow = false",
    )
    .step-main-block(:class="{active: !isHaveRequisites}")
      .step-number
        | 1
      .step-title Отправьте реквизиты покупателю
      .step-description {{ paymentDescription }}
      .place-for-number(v-if="isHaveRequisites")
        .place-for-scroll(refs="vs_req")
          .payment-number-s(@click="copyToClipboard(deal.requisites.seller_info)") {{ deal.requisites.seller_info }}
      RequisiteManual(
        v-else,
        @addRequisite="sendManualRequisite",
      )
      .select-requisite(
        v-if="showSelector && !isHaveRequisites && requisites.length > 0"
        @click="selectWindow = true",
      )
        span или
        .select-requisite__button
          | Выбрать из ранее добавленных
    .step-main-block(:class="{active: isHaveRequisites}")
      .step-number
        | 2
      .step-title
        | Отправка криптовалюты на счет покупателя
      .step-description
        | После получения денег от покупателя, отправьте криптовалюту на его счет
      .pb-4.pt-4
        button.btn.btn-success.w-100.btn-finex(
          v-if="showFinishButton",
          :disabled="!isHaveRequisites",
          @click="$emit('acceptDialog', {action: 'finish', typeText: 'Подтвердить получение'})",
          type='button'
        ) Отправить криптовалюту
</template>
<script>
import MaskedInput from 'vue-text-mask';
import RequisiteManual from './Partials/RequisiteManual';
import RequisitePopup from './Partials/RequisitePopup';

export default {
  components: {
    MaskedInput,
    RequisiteManual,
    RequisitePopup,
  },
  props: {
    currentStep: {
      type: Number,
      default: 4,
    },
    deal: {
      type: Object,
      default() {
        return {}
      },
    },
    isHaveRequisites: {
      type: Boolean,
      default: false,
    },
    isFinished: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectWindow: false,
    }
  },
  computed: {
    paymentDescription() {
      return `В вашем объявлении выставлен способ продажи через ${this.paymentTitle}. Отправьте покупателю необходимые реквизиты.`;
    },
    paymentTitle() {
      return this.deal.bank ? `${this.deal.payment_system.title} «${this.deal.bank.label}»` : this.deal.payment_system.title;
    },
    dealTitle() {
      return this.deal.bank ? this.deal.bank.label : this.deal.payment_system.title;
    },
    showFinishButton() {
      return this.currentStep === 1 || this.currentStep === 2 || this.currentStep === 5;
    },
    showSelector() {
      if (this.deal.ad.requisites === null) {
        return false;
      } else if (this.deal.ad.requisites.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    requisites() {
      if (this.deal.bank && this.deal.bank.value) {
        return this.deal.ad.requisites.filter(item => item.bank.id === this.deal.bank.value)
      }
      return this.deal.ad.requisites;
    },
  },
  methods: {
    sendSavedRequisite(status, id) {
      this.selectWindow = status;
      let data = {
        requisite_id: id,
      };
      if (id !== null) {
        this.$emit('acceptDialog', {
          action: 'requisite',
          typeText: 'Отправить реквизиты',
          info: data,
        });
      } else {
        this.$alert('Пожалуйста, введите реквизиты', {
          showClose: true,
          type: 'error'
        });
      }
    },
    sendManualRequisite(data) {
      if (data) {
        this.$emit('acceptDialog', {
          action: 'manualRequisite',
          typeText: 'Отправить реквизиты',
          info: data,
        });
      } else {
        this.$alert('Пожалуйста, введите реквизиты', {
          showClose: true,
          type: 'error'
        });
      }
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value)
          .then(() => {
            this.$message({
              message: 'Адрес кошелька успешно скопирован',
              type: 'success',
            });
          })
          .catch(err => {
            console.log('Ошибка при копировании адреса', err);
          })
    }
  }
}
</script>
<style lang="scss">
  .payment-info-payment_system {
    font-size: 14px;
    color: $blue600;
    position: absolute;
    top: 2px;
    right: 0;
  }

  .place-for-number {
    &::-webkit-scrollbar {
      cursor: pointer;
      position: absolute;
      margin: auto;
      transition: opacity 0.5s ease 0s;
      user-select: none;
      border-radius: inherit;
      height: 61.3452%;
      width: 6px;
      transform: translateY(0%);
      left: 0px;
      right: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(188, 201, 239);
      cursor: pointer;
      position: absolute;
      margin: auto;
      transition: opacity 0.5s ease 0s;
      user-select: none;
      border-radius: inherit;
      height: 61.3452%;
      opacity: 1;
      width: 6px;
      transform: translateY(0%);
      left: 0px;
      right: 0px;
    }
    .place-for-scroll {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .payment-number-s {
        width: 100%;
        word-wrap: break-word;
      }
    }
  }

  .select-requisite {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    color: $blue600;

    span {
      text-transform: lowercase;
    }

    &__button {
      font-size: 16px;
      text-transform: lowercase;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
</style>
