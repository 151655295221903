<template lang="pug">
  .review-container__content-blocking
    .feedback-comment-type__container
      .feedback-comment-type__image(:class="`deal-rating-${feedbackType}`")
    .feedback-review__container
      .feedback-review__radio-input-group
        .radio-input
          input#review-container-2(
            :checked="!showCommentForm"
            type='radio'
            @click="changeCommentType(false)"
          )
          label(for='review-container-2')
            | {{feedbackType === 'negative' ? 'Заблокировать без отзыва' : 'Оценить без отзыва'}}
        .radio-input
          input#review-container-1.check(
            :checked="showCommentForm"
            type='radio'
            @click="changeCommentType(true)"
          )
          label(for='review-container-1')
            | {{feedbackType === 'negative' ? 'Заблокировать с отзывом' : 'Оценить с отзывом'}}
      div.feedback-comment-container
        input.message-box__textarea.message-box__textarea-long.feedback-area(
          :class="{ 'feedback-area__hidden': !showCommentForm }"
          contenteditable="true"
          data-text="Напишите Ваш отзыв..."
          v-model="commentMessage"
          ref="commentInput"
        )
      button.btn.btn-success.w-100.mt-2(@click="sendComment" type='button') Оценить
</template>

<script>
  export default {
    props: {
      feedbackType: {
        type: String,
        default: 'neutral',
      },
    },
    data() {
      return {
        showCommentForm: false,
        commentMessage: '',
      }
    },
    methods: {
      async changeCommentType(type) {
        this.showCommentForm = type;

        if (type) {
          await this.$nextTick();
          this.$refs.commentInput.focus();
        }
      },
      sendComment() {
        this.$emit('sendComment', this.showCommentForm ? this.commentMessage : '');
      },
    }
  }
</script>

<style lang="scss">
  .feedback-comment-type__container {
    width: 32px;
    height: 32px;
    text-align: center;
    margin: auto auto 1rem;
  }

  .feedback-comment-type__image {
    width: 100%;
    height: 100%;
  }

  .feedback-review__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .feedback-comment-container {
    width: 100%;
    margin: 15px auto 5px;
  }

  .feedback-comment-container .feedback-area {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $gray800;

    background: $blue100;
    border: 1px solid $blue300;
    border-radius: 6px;
    padding: 11px 16px;

    &__hidden {
      display: none;
    }
  }
</style>
