<template lang="pug">
  #ModalWindow.Modal
    .modal-container
      h4.modal-container__title Выберите реквизит:
      vue-scroll.modal-container__inner
        .list-item(v-for="item in requisiteList")
          .list-item__title {{ item.type.title }}
          .list-item__requisite {{ item.requisite }}
          .list-item__buttons
            i.el-icon-success.list-item__buttons--accept(
              @click="selectedRequisite = item.id",
              v-if="selectedRequisite === null"
            )
            i.el-icon-error.list-item__buttons--cancel(
              @click="selectedRequisite = null",
              v-if="item.id === selectedRequisite"
            )
      .modal-buttons
        button.btn.btn-success.btn-finex(
          @click="sendRequisite",
          :disabled="selectedRequisite === null",
          type='button',
        ) Отправить реквизит
        button.btn.btn-danger.btn-finex(
          @click="$emit('closeWindow')",
          type='button',
        ) Отмена

</template>

<script>
import utils from '../../mixins/utils';
  export default {
    mixins: [utils],
    props: {
      requisites: {
        type: Array,
        default() {
          return [];
        },
      },
    },
    data() {
      return {
        selectedRequisite: null,
      }
    },
    computed: {
      requisiteList(){
        return this.requisites.sort((a, b) => b.priority - a.priority)
      }
    },
    methods: {
      sendRequisite() {
        this.$emit('sendRequisite', false, this.selectedRequisite);
      },
    }
  }
</script>

<style lang="scss" scoped>
  #ModalWindow {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1100;
    background: rgba(0, 0, 0, 0.7);
  }

  .modal-container {
    display: flex;
    background: #fff;
    width: 100%;
    max-width: 600px;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 20px;
    max-height: 75%;
    overflow: hidden;
    flex-direction: column;

    &__inner {
      display: flex;
      overflow: hidden;
      flex-direction: column;
    }

    &__title {
      text-align: center;
      font-size: 24px;
    }
  }
  .modal-item {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 30px;
    margin: 0 20px;

    &-title {
      width: 100%;
      padding: 0px 0px 5px 0px;
      margin: 0;
      border-bottom: 1px solid #2464EB;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      &__text {
        font-size: 18px;
        font-weight: bold;
      }

      &__date {
        color: #fff;
        background: #2464EB;
        padding: 2px 15px;
        font-size: 12px;
        border-radius: 25px;
      }
    }

    &__text {
      background: $blue100;
      padding: 15px;
      width: 100%;
      border: 1px solid $blue300;
      border-top: 0;
      font-size: 14px;
      white-space: pre-wrap;
      word-break: break-all;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  .modal-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

    button {
      width: 40%;
      margin-right: 20px;
    }
  }

  .list-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    margin: 0 20px;


    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $blue300;
      border: 1px solid $blue300;
      border-radius: 6px;
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      font-size: 14px;
      padding: 0 15px;
      width: 25%;
      align-self: stretch;
    }

    &__requisite {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $blue100;
      border: 1px solid $blue300;
      border-radius: 6px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      font-size: 14px;
      padding: 5px 15px;
      min-height: 34px;
      white-space: pre-wrap;
      width: 75%;
      color: $blue600;
      word-break: break-word;

      &.other-requisite {
        justify-content: flex-start;
      }
    }

    &__buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      width: 34px;

      i:hover {
       cursor: pointer;
      }

      &--accept {
        color: $blue600;
      }

      &--cancel {
        color: #FF2D55;
      }
    }
  }
</style>
