<template lang="pug">
  .change-main-block
    .tabbedblocks-content.container
      .row.market-header
        .col-12
          span.title-page {{ deal.is_sale ? 'Продажа' : 'Покупка' }}
          span.cursor-link.deal-details(@click="isDetailsOpen = true")
            | Детали сделки
          .status-in-top
            .status-current
              .status-icon(:class="`status-icon-${currentStatus.icon}`")
              span {{ currentStatus.title }}
        .col-12.cost-main-block
          .cost-of-exchange
            .main-price {{ `${deal.crypto_amount} ${deal.ad.crypto_currency.code.toUpperCase()}` }}
            | <nobr>за <b>{{ `${thousandSeparator(deal.fiat_amount)} ${deal.ad.currency.code.toUpperCase()}` }}</b></nobr> <nobr>по курсу <b>{{ `${thousandSeparator(deal.price)} ${deal.ad.currency.code.toUpperCase()}` }}</b></nobr>
          .commission.el-row--flex
            .info-block
              .title-commission
                | Дата создания
              .value-commission
                | #[span {{ divideDate(deal.created_at).dateDay }}] #[time {{ divideDate(deal.created_at).time }}]
            .info-block
              .title-commission
                | Сделка с
              router-link.value-commission(tag="div", :to="{name: 'Profile', params: {id: getOpponentId}}")
                span.user-link {{ getOpponentLogin }}
            .info-block
              .title-commission
                | Способ оплаты
              .value-commission
                | {{ deal.bank ? `${deal.payment_system.title} «${deal.bank.label}»` : deal.payment_system.title }}
            .info-block
              .title-commission
                | Комиссия
              .value-commission
                | {{`${cryptoCommission} ${deal.ad.crypto_currency.code.toUpperCase()}`}}
            .info-block
              .title-get
                | {{ deal.is_sale ? 'Отдаете' : 'Получаете' }}
              .value-get
                | {{`${getCryptoAmount(isOffer, deal)} ${deal.ad.crypto_currency.code.toUpperCase()}`}}
        .claims(v-if="dealClaims.length")
          .claims__container
            .claims__content
              img.icon(v-if="openedClaim" src="/awesome-icons/warning.svg")
              img.icon(v-else src="/awesome-icons/info.svg")
              a.claims__link(@click="showClaims")
                | {{ dealClaimsInfoInOneLine(dealClaims, { includeAmount: true, noLastSentenceDot: true }) }}
    el-dialog(
      title="Детали сделки"
      :visible.sync="isDetailsOpen"
      top="48px"
      width="500px"
    )
      DealDetails(:deal-id="deal.id" :claims="sortedClaims")
    el-dialog(
      title="Претензии"
      :visible.sync="isClaimsModalOpened"
      top="48px"
      width="500px"
    )
      ClaimHistoryList(:claims="sortedClaims")
</template>

<script>
import { mapGetters } from 'vuex';
import { DEAL_CLAIMS } from '@/store/actions/deal';
import utils from '@/components/mixins/utils';
import claims from '@/components/mixins/claims';
import DealDetails from "./DealDetails.vue";
import ClaimHistoryList from './ClaimHistoryList.vue';

export default {
  name: 'DealInfo',
  components: {
    DealDetails,
    ClaimHistoryList,
  },
  mixins: [
    utils,
    claims,
  ],
  props: {
    deal: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isDetailsOpen: false,
      isClaimsModalOpened: false,
    };
  },
  computed: {
    ...mapGetters([
      'getProfile',
      'get_status',
      'dealClaims',
    ]),
    isOffer() {
      return this.getProfile.id === this.deal.ad.author.id;
    },
    getOpponentId() {
      return this.deal.author.id === this.getProfile.id ? this.deal.offer.id : this.deal.author.id;
    },
    getOpponentLogin() {
      if (this.deal.author.login === this.getProfile.login) {
        return this.deal.offer.login
      } else if (this.deal.offer.login !== this.getProfile.login && this.deal.author.login !== this.getProfile.login) {
        return this.deal.offer.login
      } else {
        return this.deal.author.login
      }
    },
    cryptoCommission() {
      return this.isOffer ? this.deal.commissions.offer : this.deal.commissions.client;
    },
    currentStatus() {
      const statuses = {
        [this.get_status('Verification', 'id')]: {title: 'Сделка подтверждается системой', icon: 'waiting'},
        [this.get_status('Finishing', 'id')]: {title: 'Сделка завершается системой', icon: 'waiting'},
        [this.get_status('Cancellation', 'id')]: {title: 'Сделка отменяется системой', icon: 'waiting'},
        [this.get_status('Autocancellation', 'id')]: {title: 'Сделка отменяется автоматически', icon: 'waiting'},
        [this.get_status('Seller requisite', 'id')]: {title: 'Ожидается оплата от покупателя', icon: 'checked'},
        [this.get_status('Verified', 'id')]: {title: 'Ожидаются реквизиты продавца', icon: 'checked'},
        [this.get_status('Paid', 'id')]: {title: 'Сделка помечена оплаченной', icon: 'checked'},
        [this.get_status('Finished', 'id')]: {title: 'Сделка завершена', icon: 'checked'},
        [this.get_status('Canceled', 'id')]: {title: 'Сделка отменена', icon: 'cancel'},
        [this.get_status('In dispute', 'id')]: {title: 'Идет спор', icon: 'waiting'},
        [this.get_status('On time check', 'id')]: {title: 'Идет проверка таймеров', icon: 'waiting'},
      };
      return statuses[this.deal.status.id];
    },
    openedClaim() {
      return this.findOpenedClaim(this.dealClaims);
    },
    sortedClaims() {
      return this.sortClaims(this.dealClaims);
    },
  },
  created() {
    this.loadClaims();
  },
  methods: {
    loadClaims() {
      this.$store.dispatch(DEAL_CLAIMS, { deal_id: this.deal.id });
    },
    showClaims() {
      if (this.openedClaim) {
        this.$router.push({
          name: 'Claims',
          query: {
            incoming: !this.isClaimOpenedByCurrentUser(this.openedClaim) ? null : undefined,
            closed: this.openedClaim ? undefined : 1,
            deal_id: String(this.deal.id),
          },
        });
      } else {
        this.isClaimsModalOpened = true;
      }
    },
  },
}
</script>

<style lang="scss">
.user-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.commission {
  flex-wrap: wrap;
  gap: 12px;
}

.value-commission {
  text-overflow: ellipsis;
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
}

.claims {
  margin-top: 32px;
  padding-right: 15px;
  padding-left: 15px;

  .claims__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    margin-left: -8px;
    padding: 4px 8px;
    border-radius: 6px;
  }

  .claims__content {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .claims__link {
    color: $blue600 !important;

    &:hover {
      text-decoration: underline !important;
      color: $blue600 !important;
      cursor: pointer !important;
    }
  }
}
</style>
