import { mapGetters } from 'vuex';
import localRu from '@/components/mixins/localRu';

const CLAIM_WORD_FORMS = {
  one: 'претензия',
  two: 'претензии',
  five: 'претензий',
};

const CLOSED_CLAIM_WORD_FORMS = {
  one: 'закрыта',
  two: 'закрыты',
  five: 'закрыто',
};

const WAS_WORD_FORMS = {
  one: 'была',
  two: 'были',
  five: 'было',
};

export default {
  mixins: [
    localRu,
  ],
  computed: {
    ...mapGetters({
      profile: 'getProfile',
    }),
  },
  methods: {
    isClaimOpenedByCurrentUser(claim) {
      return claim.author.id === this.profile.id;
    },
    findOpenedClaim(claims) {
      // can not be more than one opened claim for one deal
      return claims.find(({ status }) => status.title.toLowerCase() === 'opened');
    },
    /**
     * @returns single line description about deal claims or `''` if claims list is empty
     */
    dealClaimsInfoInOneLine(claims, { includeAmount, noLastSentenceDot } = {}) {
      const joinedBySpaces = parts => parts.flat().filter(x => Boolean(x)).join(' ');
      const sentence = parts => joinedBySpaces(parts) + '.';
      const cutLastCharacter = string => string.slice(0, string.length - 1);

      if (!claims || !claims.length) {
        return '';
      }

      const openedClaim = this.findOpenedClaim(claims);

      const parts = openedClaim ? [
        this.claimStatusInOneLine(openedClaim, { includeStatus: true, includeAmount }),
        claims.length > 1 ? sentence([
          'Ранее',
          this.countedElementsNumberWordFormRu(claims.length - 1, WAS_WORD_FORMS),
          this.countedElementsNumberWordFormRu(claims.length - 1, CLOSED_CLAIM_WORD_FORMS),
          'ещё',
          String(claims.length - 1),
          this.countedElementsNumberWordFormRu(claims.length - 1, CLAIM_WORD_FORMS),
        ]) : null,
      ] : claims.length === 1 ? [
        this.claimStatusInOneLine(claims[0], { includeStatus: true, includeAmount }),
      ] : claims.length > 1 ? [
        this.capitalizeString(this.countedElementsNumberWordFormRu(claims.length, CLOSED_CLAIM_WORD_FORMS)),
        String(claims.length),
        this.countedElementsNumberWordFormRu(claims.length, CLAIM_WORD_FORMS) + '.',
      ] : [];

      const joinedString = this.capitalizeString(joinedBySpaces(parts));

      return noLastSentenceDot ? cutLastCharacter(joinedString) : joinedString;
    },
    claimStatusInOneLine(claim, { includeStatus, includeAmount, noLastSentenceDot } = {}) {
      const joinedBySpaces = parts => parts.flat().filter(x => Boolean(x)).join(' ');
      const sentence = parts => joinedBySpaces(parts) + '.';
      const cutLastCharacter = string => string.slice(0, string.length - 1);

      const claimStatus = claim.status.title.toLowerCase();

      const parts = [
        sentence([
          includeStatus ? this.claimStatus(claim, { forSingleLineDescription: true }) : null,
          'претензия',
          this.claimFromWho(claim),
          includeAmount ? this.claimForTheAmount(claim) : null,
        ]),
        includeStatus && claimStatus === 'pending' ? 'Происходит списание.' : null,
      ];

      const joinedString = this.capitalizeString(joinedBySpaces(parts));

      return noLastSentenceDot ? cutLastCharacter(joinedString) : joinedString;
    },
    claimStatus(claim, { forSingleLineDescription } = {}) {
      const claimStatus = claim.status.title.toLowerCase();

      return (
        claimStatus === 'rejected' ?
          'Отклонена' :
        claimStatus === 'deleted' ?
          'Удалена' :
        claimStatus === 'applied' ?
          'Принята' :
        claimStatus === 'pending' ?
          forSingleLineDescription ? 'Принята' : 'Списание'
        :
          'Открыта'
      );
    },
    claimFromWho(claim) {
      return this.isClaimOpenedByCurrentUser(claim) ? 'от вас' : `от ${claim.author.login}`;
    },
    claimAmount(claim) {
      const currencyCode = claim.deal.ad.currency.code.toUpperCase();
      const cryptoCurrencyCode = claim.deal.ad.crypto_currency.code.toUpperCase();
      return `${claim.fiat_amount} ${currencyCode} (${claim.crypto_amount} ${cryptoCurrencyCode})`;
    },
    claimForTheAmount(claim) {
      return `на сумму ${this.claimAmount(claim)}`;
    },
    /**
     * @param {string} string 
     * @returns {string}
     */
    capitalizeString(string) {
      return string.length ? string[0].toUpperCase() + string.slice(1) : string;
    },
    /**
     * @param {string} name 
     * @returns {string}
     */
    getClaimStatus(name) {
      const status = this.$store.state.claimStatuses.find(status => status.name === name);
      return status ? status.title : '';
    },
    sortClaims(claims, descending = false) {
      const sign = descending ? -1: 1;
      const comparator = (a, b) =>
        sign * (Date.parse(a.created_at) - Date.parse(b.created_at));

      return claims.sort(comparator);
    },
  },
};
