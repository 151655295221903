<template lang="pug">
.deal-details-container
  p #[b Id:] {{deal.id}}
  p #[b Текущий статус:] {{deal.status}}
  p #[b Продавец: ]
    router-link(:to="{name: 'Profile', params: {id: deal.saler.id}}")
      | {{deal.saler.login}} {{deal.isSaleForCurrentUser ? '' : '(вы)'}}
  p #[b Покупатель: ]
    router-link(:to="{name: 'Profile', params: {id: deal.buyer.id}}")
      | {{deal.buyer.login}} {{deal.isSaleForCurrentUser ? '(вы)' : ''}}

  p #[b Курс:] {{ deal.price }} {{ deal.currency.fiat }}
  p #[b Сумма в фиате:] {{ deal.sum.fiat }} {{ deal.currency.fiat }}
  p #[b Сумма в криптовалюте:] {{ deal.sum.cryptoDeal }} {{ deal.currency.crypto }}
  p #[b Комиссия:] {{ deal.commission }} {{ deal.currency.crypto }}
  p #[b Сумма с учётом комиссии:] {{ deal.sum.cryptoTotal }} {{ deal.currency.crypto }}
  p #[b Способ оплаты: ] {{ deal.bank ? `${deal.payment_system.title} «${deal.bank.label}»` : (deal.payment_system && deal.payment_system.title) }}

  h2 Реквизиты
  p: span(v-for="line in deal.requisitesByLines")
    | {{line}}
    br
  h2 Условия сделки
  p: span(v-for="line in deal.conditionsByLines")
    | {{line}}
    br

  h2 История
  table.status-history
    tr(v-for="status in deal.statusHistory")
      td {{ status.title }}
      td #[span.time {{ status.time }}], #[span.date {{ status.date }}]

  h2 Претензии
  div(v-if="isDealClaimsLoading") Загрузка...
  ClaimHistoryList(v-else-if="claims.length" :claims="claims")
  p(v-else) Нет претензий.
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_INCOMING_CLAIMS, GET_OUTGOING_CLAIMS } from '@/store/actions/claims';
import utils from '@/components/mixins/utils';
import localRu from '@/components/mixins/localRu';
import claims from '@/components/mixins/claims';
import ClaimHistoryList from './ClaimHistoryList.vue';

export default {
  name: 'DealDetails',
  components: {
    ClaimHistoryList,
  },
  mixins: [
    utils,
    localRu,
    claims,
  ],
  props: {
    dealId: {
      type: Number,
      required: true,
    },
    claims: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      dealInfo: 'dealInfo',
      profile: 'getProfile',
      loading: 'loading',
    }),
    deal() {
      const DEFAULT_CONDITIONS = 'Нет особых условий.';
      const DEFAULT_REQUISITES = 'Реквизиты не были указаны.';

      const rawDeal = this.dealInfo(this.dealId).info;
      const isMeResponder = rawDeal.author.id === this.profile.id;
      const isMeAuthor = !isMeResponder;
      const commission = isMeResponder ? rawDeal.commissions.client : rawDeal.commissions.offer;

      return {
        id: rawDeal.id,
        isSaleForCurrentUser: rawDeal.ad.is_sale && isMeResponder,
        status: this.localizedStatusTitle(rawDeal.status.title),
        statusHistory: rawDeal.status_history.map(({ title, created_at }) => {
          const moment = this.$moment(created_at, 'YYYY-MM-DD HH:mm:ss ZZ');
          const date = moment.format('DD.MM.YYYY');
          const time = moment.format('HH:mm:ss');
          return {
            title: this.localizedStatusTitle(title),
            date,
            time,
          };
        }),
        buyer: rawDeal.is_sale && isMeResponder ? rawDeal.offer : rawDeal.author,
        saler: rawDeal.is_sale && isMeResponder ? rawDeal.author : rawDeal.offer,
        requisitesByLines: (rawDeal?.requisites?.seller_info || DEFAULT_REQUISITES).split('\n'),
        conditionsByLines: (rawDeal?.conditions || DEFAULT_CONDITIONS).split('\n'),
        price: this.thousandSeparator(rawDeal.price),
        commission,
        sum: {
          fiat: this.thousandSeparator(rawDeal.fiat_amount),
          cryptoDeal: rawDeal.crypto_amount,
          cryptoTotal: this.getCryptoAmount(isMeAuthor, rawDeal),
        },
        currency: {
          fiat: rawDeal.ad.currency.code.toUpperCase(),
          crypto: rawDeal.ad.crypto_currency.code.toUpperCase(),
        },
        payment_system: rawDeal.payment_system,
        bank: rawDeal.bank,
      };
    },
    isDealClaimsLoading() {
      return this.loading(GET_INCOMING_CLAIMS) === 'loading'
        || this.loading(GET_OUTGOING_CLAIMS) === 'loading';
    },
  },
  methods: {
    localizedStatusTitle(statusTitle) {
      const statuses = {
        'Verification': 'Сделка подтверждается системой',
        'Finishing': 'Сделка завершается системой',
        'Cancellation': 'Сделка отменяется системой',
        'Autocancellation': 'Сделка отменяется автоматически',
        'Seller requisite': 'Ожидается оплата от покупателя',
        'Verified': 'Ожидаются реквизиты продавца',
        'Paid': 'Сделка помечена оплаченной',
        'Finished': 'Сделка завершена',
        'Canceled': 'Сделка отменена',
        'In dispute': 'Идет спор',
        'On time check': 'Идет проверка таймеров',
      };

      return statuses[statusTitle];
    },
  },
};
</script>

<style lang="scss" scope>
.deal-details-container {
  h2 {
    margin-top: 2rem;
    font-size: 1.125rem;
  }

  p {
    margin-bottom: 0.5rem;
  }

  table.status-history {
    width: 100%;
  }

  .date,
  .time {
    letter-spacing: 0.02em;
  }
}
</style>
