<template lang="pug">
  .requisites-input-main__container
    .create-requsites-input
      .requisite__input(
        v-loading='isLoading',
        element-loading-text='Проверка реквизита',
      )
        the-mask.create-requsites-input__item(
          v-if="currentType === getAllRequisiteTypes.PHONE",
          :placeholder="phoneMask",
          :mask="phoneMask",
          :masked="true",
          :class="{error: requisiteError}",
          v-model="requisite",
        )
        the-mask.create-requsites-input__item(
          v-if="currentType === getAllRequisiteTypes.CARD",
          :mask="cardMask",
          :masked="true",
          :class="{error: requisiteError}",
          v-model="requisite",
        )
        .requisites-textarea__container(v-if="currentType === getAllRequisiteTypes.OTHER")
          textarea.requisites-textarea__input(
          resize="none",
          v-model="requisite",
          rows="1",
          placeholder='Напишите сообщение',
          maxlength='255',
          :class="{error: requisiteError}",
        )
          .requisites-textarea__length {{ messageLength }}
      button.btn.btn-success.w-100.btn-finex(
        @click="addRequisite",
        :disabled="requisiteError || isInactive",
        type='button',
        ) Отправить реквизиты
    .requisites-error(v-if="requisiteError") {{ requisiteErrorReason }}
</template>

<script>
  import { mapGetters } from 'vuex';
  import { REQUISITE_VALIDATE } from '../../../store/actions/ad';

  export default {
    props: {
      country_id: {
        type: Number,
        default: 1,
      },
      selectedType: {
        type: Object,
        default() {
          return {};
        }
      },
    },
    data() {
      return {
        requisite: '',
        requisiteError: false,
        isInactive: true,
        requisiteErrorReason: '',
      }
    },
    computed: {
      ...mapGetters([
        'loading',
        'getUserWalletAddress',
        'getAllRequisiteTypes',
      ]),
      isLoading() {
        return this.loading(REQUISITE_VALIDATE) === 'loading';
      },
      currentType() {
        return this.selectedType.alias;
      },
      phoneMask() {
        const masks = { // TODO: Move to config if necessary
          1: ['+7 (###) ###-##-##'],
          4: ['+380 (##) ###-##-##'],
          5: ['+375 (##) ###-##-##'],
        };
        return masks[this.country_id];
      },
      cardMask() {
        return ['#### #### #### ####', '#### #### #### #### #', '#### #### #### #### ##', '#### #### #### #### ###'];
      },
      messageLength() {
        return `${this.requisite.length} / 255`
      },
      formattedRequisite() {
        if (this.currentType === this.getAllRequisiteTypes.OTHER) {
          return this.requisite;
        } else {
          let requisite = this.requisite;
          requisite = requisite.split('_').join('');
          requisite = requisite.split('-').join('');
          requisite = requisite.split(' ').join('');
          requisite = requisite.split('(').join('');
          requisite = requisite.split(')').join('');
          requisite = requisite.split('+').join('');
          requisite = requisite.trim();
          return requisite;
        }
      },
    },
    watch: {
      requisite(val) {
        this.isInactive = true;
        if (val.length === 0) {
          return;
        }
        this.debouncedRequisitesCheck(val)
      },
      selectedType() {
        this.resetRequisite();
      },
      country_id() {
        if (this.currentType === this.getAllRequisiteTypes.PHONE) {
          this.resetRequisite();
        }
      },
    },
    created() {
      this.debouncedRequisitesCheck = _.debounce(this.requisiteCheck, 500);
    },
    methods: {
      async requisiteCheck(requisite) {
        if (requisite === this.editRequisite) {
          this.isInactive = false;
          return;
        }
        const validate = await this.$store.dispatch(REQUISITE_VALIDATE, {
          alias: this.selectedType.alias, requisite: this.formattedRequisite
        });
        this.isInactive = false;
        if (validate && validate.is_valid) {
          this.requisiteError = false;
          this.requisite = this.formattedRequisite
        } else if (validate) {
          this.requisiteError = true;
          this.requisiteErrorReason = validate.error;
        }
      },
      addRequisite() {
        if (this.isInactive === false || this.requisiteError === false) {
          let data = {
            type: this.selectedType.alias,
            requisite: this.requisite,
            currency_id: 1
          }
          this.$emit('addRequisite', data);
        }
      },
      resetRequisite() {
        this.requisite = '';
        this.requisiteError = false;
        this.requisiteErrorReason = '';
      },
    }
  }
</script>

<style lang="scss" scoped>

  .requisites-input-main__container {
    width: 100%;

    .create-requsites-input {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 15px;

      .requisite__input {
        width: 100%;
        margin-bottom: 15px;

        .create-requsites-input__item {
          background: $blue100;
          border: 1px solid $blue300;
          height: 50px;
          border-radius: 6px;
          width: 100%;
          padding-left: 20px;
          padding-right: 10px;
          color: $blue600;
          outline: none;

          &.error {
            border: 1px solid red;
            box-shadow: 1px 1px 5px #f54545a6;
          }
        }

        .requisites-textarea__container {
          width: 100%;

          .requisites-textarea__input {
            outline: none;
          }
          .requisites-textarea__input {
            background: $blue100;
            border: 1px solid $blue300;
            width: 100%;
            padding: 5px;
            border-radius: 6px;
            min-height: 80px;
            max-height: 200px;

            &.error {
              border: 1px solid red;
              box-shadow: 1px 1px 5px #f54545a6;
            }
          }

          .requisites-textarea__length {
            text-align: right;
            font-size: 12px;
            color: #929292;
          }
        }
      }
    }

    .requisites-error {
      padding: 5px;
      font-size: 12px;
      color: red;
    }
  }

</style>
